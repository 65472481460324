<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室人员考勤管理</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item label="时间：">
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changestartTime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <div>
              <el-button style="width: 120px;" type="primary" v-if="cesuanClick" @click="attexport" :loading="cesuanClickTwo">导出数据</el-button>
              <el-button v-else type="info" :loading="cesuanClickTwo"></el-button>
            </div>
          </el-form>

        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
              prop="basePeriodTime"
              label="时间"
              align="center"
          >
          </el-table-column>
          <el-table-column prop="departmentName" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column label="填报状态" align="center">
            <template slot-scope="scope">
<!--              填报状态 0未填报 1已填报-->
              <div style="color: #81D3F8" v-if="scope.row.status=='0'">未填报</div>
              <div style="color: #FACD91" v-if="scope.row.status=='1'">已填报</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template style="display: flex" slot-scope="scope">
              <!--待填写显示填报-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status=='0'"
                  @click="fillClick(scope.row)"
              >填报</el-button>
              <!--审核失败显示重新填写、查看驳回原因-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status=='1'"
                  @click="refillClick(scope.row)"
              >修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog
        :title="fillTitle"
        :visible.sync="FillingVisible"
        :before-close="FillingVisibleClose"
        width="600px"
        top="1vh"
    >
      <el-form
          :model="Performance"
          ref="PerformanceRef"
          :rules="PerformanceRules"
          :inline="true"
          label-width="120px"
      >

        <el-form-item prop="annualVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              年休假（工龄假）
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.annualVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="sickleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              病假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.sickleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="materleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              产假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.materleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="marrleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              婚假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.marrleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="homeleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              探亲假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.homeleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="funleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              丧假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.funleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="publeVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              公假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.publeVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="persleVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              事假
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.persleVal" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="absentee" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              旷工
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.absentee" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="learconEduca" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              学习进修
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.learconEduca" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="fullTraPer" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              试工期满
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.fullTraPer" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="retire" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              退休
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.retire" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="departAdjus" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              科室调整
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.departAdjus" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="attenSitua" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              出勤情况
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.attenSitua" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="remark" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              备注
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.remark" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="btnShow">
          <div class="btnSize">
            <el-button type="primary" @click="onsubmit"
            >提交数据</el-button
            >
          </div>
        </el-form-item>

      </el-form>
    </el-dialog>

  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      // 导出按钮置灰
      cesuanClick: true,
      cesuanClickTwo: false,
      // 只选择自己想选的年份和月份
      creactMonthArr:['2022-05','2022-08'],
      pickerOptionsEndequivalent: {
        disabledDate: (time) => {
          // console.log(time)
          const year = time.getFullYear()
          let month = time.getMonth() + 1
          if (month < 10) month = '0' + month
          const ym = year + '-' + month
          return !this.creactMonthArr.includes(ym)
        },
      },
      startTime:'',
      disabled:false,
      btnShow:true,
      fillTitle:'填报',
      // 填报
      Performance: {
        annualVal:"0",//年休假（工龄假）
        sickleVal:'0',//病假
        materleVal:'0',//产假
        marrleVal:"0",//婚假
        homeleVal:"0",//探亲假
        funleVal:"0",//丧假
        publeVal:"0",//公假
        persleVal:"0",//事假
        absentee:"0",//旷工
        learconEduca:"0",//学习进修
        fullTraPer:"0",//试工期满
        retire:"0",//退休
        departAdjus:"0",//科室调整
        attenSitua:"0",//出勤情况
        remark:""//备注
      },
      // 绩效分配结构规则
      PerformanceRules: {
        perexpVal: [
          {
            required: true,
            message: "请输入镇痛分娩",
            trigger: "blur",
          },
        ],
        annualVal: [
          {
            required: true,
            message: "请输入年休假（工龄假）",
            trigger: "blur",
          },
        ],
        sickleVal: [
          {
            required: true,
            message: "请输入病假",
            trigger: "blur",
          },
        ],
        materleVal: [
          {
            required: true,
            message: "请输入产假",
            trigger: "blur",
          },
        ],
        marrleVal: [
          {
            required: true,
            message: "请输入婚假",
            trigger: "blur",
          },
        ],
        homeleVal: [
          {
            required: true,
            message: "请输入探亲假",
            trigger: "blur",
          },
        ],
        funleVal: [
          {
            required: true,
            message: "请输入丧假",
            trigger: "blur",
          },
        ],
        publeVal: [
          {
            required: true,
            message: "请输入公假",
            trigger: "blur",
          },
        ],
        persleVal: [
          {
            required: true,
            message: "请输入事假",
            trigger: "blur",
          },
        ],
        absentee: [
          {
            required: true,
            message: "请输入旷工",
            trigger: "blur",
          },
        ],
        learconEduca: [
          {
            required: true,
            message: "请输入学习进修",
            trigger: "blur",
          },
        ],
        fullTraPer: [
          {
            required: true,
            message: "请输入试工期满",
            trigger: "blur",
          },
        ],
        retire: [
          {
            required: true,
            message: "请输入退休",
            trigger: "blur",
          },
        ],
        departAdjus: [
          {
            required: true,
            message: "请输入科室调整",
            trigger: "blur",
          },
        ],
        attenSitua: [
          {
            required: true,
            message: "请输入出勤情况",
            trigger: "blur",
          },
        ]
      },
      // 填报弹出框
      FillingVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      departmentName:sessionStorage.getItem("departmentName"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      bascId:"",
      basbasePeriodTime:"",
      basStatus:"",
      bascname:"",
      basDepId:''
    };
  },
  created() {
    this.getDateTime()
  },
  methods: {
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const day = date.date >= 10 ? date.date : "0" + date.date;
      let updateTime = date.year + "-" + newmonth + "-" + day;
      return updateTime;
    },
    async attexport(){
      this.cesuanClick = false;
      this.cesuanClickTwo = true;
      let data
      if(window.sessionStorage.getItem("departmentName")=='人事科'){
        data = {
          basePeriodTime:this.startTime,//时间
          departmentId:'',
          hospitalId:window.sessionStorage.getItem("hospitalId")
        };
      }else{
        data = {
          basePeriodTime:this.startTime,//时间
          departmentId:window.sessionStorage.getItem("departmentId"),
          hospitalId:''
        };
      }
      let res = await this.$axios.attexport(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: "导出失败",
          type: "error",
        });
      } else if (res.status == 200) {
        let updateTime = this.addDate();
        let blob = new Blob([res.data], { type: "application/x-xls" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download =updateTime+"人员考勤管理" + ".xls";
        link.click();
        this.cesuanClick = true;
        this.cesuanClickTwo = false;
      }
    },
    FillingVisibleClose(){
      this.FillingVisible=false
      this.bascname=''
      this.basStatus=''
      this.bascId=''
      this.Performance={
        annualVal:"0",//年休假（工龄假）
        sickleVal:'0',//病假
        materleVal:'0',//产假
        marrleVal:"0",//婚假
        homeleVal:"0",//探亲假
        funleVal:"0",//丧假
        publeVal:"0",//公假
        persleVal:"0",//事假
        absentee:"0",//旷工
        learconEduca:"0",//学习进修
        fullTraPer:"0",//试工期满
        retire:"0",//退休
        departAdjus:"0",//科室调整
        attenSitua:"0",//出勤情况
        remark:""//备注
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.attpageList();
    },
    changestartTime(val){
      this.startTime=val
      this.attpageList();
    },
    fillClick(row) {
      this.bascId=row.id
      this.bascname=row.name
      this.basDepId=row.departmentId
      this.basbasePeriodTime=row.basePeriodTime
      this.fillTitle='填报'+row.name+row.basePeriodTime+'考勤填报'
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
    },
    refillClick(row){
      this.bascId=row.id
      this.basbasePeriodTime=row.basePeriodTime
      this.basDepId=row.departmentId
      this.fillTitle=row.name+row.basePeriodTime+'绩效填报'
      this.Performance.annualVal=row.yearHoliday
      this.Performance.sickleVal=row.sickLeave//病假
      this.Performance.materleVal=row.maternityLeave//产假
      this.Performance.marrleVal=row.marriageLeave//婚假
      this.Performance.homeleVal=row.homeLeave//探亲假
      this.Performance.funleVal=row.funeralLeave//丧假
      this.Performance.publeVal=row.fairLeave//公假
      this.Performance.persleVal=row.matterLeave//事假
      this.Performance.absentee=row.absenteeism//旷工
      this.Performance.learconEduca=row.study//学习进修
      this.Performance.fullTraPer=row.trialPeriod//试工期满
      this.Performance.retire=row.retire//退休
      this.Performance.departAdjus=row.departmentAdjust//科室调整
      this.Performance.attenSitua=row.attendanceSituation//出勤情况
      this.Performance.remark=row.remark//备注
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.attpageList();
    },
    //   获取绩效填报列表
    async attpageList() {
      let data
      if(window.sessionStorage.getItem("departmentName")=='人事科'){
        data = {
          basePeriodTime:this.startTime,//时间
          departmentId:'',
          deleteFlag:"0",
          status:"",//填报状态 0未填报 1已填报
          pageNum: this.pageNum, //页数
          pageSize: this.pageSize, //每页显示数量
        };
      }else{
        data = {
          basePeriodTime:this.startTime,//时间
          departmentId:window.sessionStorage.getItem("departmentId"),
          deleteFlag:"0",
          status:"",//填报状态 0未填报 1已填报
          pageNum: this.pageNum, //页数
          pageSize: this.pageSize, //每页显示数量
        };
      }

      let { data: res } = await this.$axios.attpageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
           let data = _qs.stringify({
              id:this.bascId,
              departmentId:this.basDepId,//科室id
              name:this.bascname,//姓名
              yearHoliday:this.Performance.annualVal,//年休假
              sickLeave:this.Performance.sickleVal,//病假
              maternityLeave:this.Performance.materleVal,//产假
              marriageLeave:this.Performance.marrleVal,//婚假
              homeLeave:this.Performance.homeleVal,//探亲假
              funeralLeave:this.Performance.funleVal,//丧假
              fairLeave:this.Performance.publeVal,//公假
              matterLeave:this.Performance.persleVal,//事假
              absenteeism:this.Performance.absentee,//旷工
              study:this.Performance.learconEduca,//学习进修
              trialPeriod:this.Performance.fullTraPer,//试工期满
              retire:this.Performance.retire,//退休
              departmentAdjust:this.Performance.departAdjus,//科室调整
              attendanceSituation:this.Performance.attenSitua,//出勤情况
              remark:this.Performance.remark,//备注
              basePeriodTime:this.basbasePeriodTime,//基期时间
              deleteFlag:"0"
            });
            let res = await this.$axios.attupdate(data);

          // console.log(res);
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.attpageList()
            this.FillingVisibleClose()
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
  margin-top: 20px;
}

/deep/ .el-input-number__decrease,/deep/ .el-input-number__increase{
  display: none;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
</style>
